import { Container } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import CookieConsent from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import React from "react";
import NavBar from "./NavBar";
import EmailVerificationBanner from "./EmailVerificationBanner";
import Footer from "./Footer";

/** @type {React.FC} */
const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <NavBar />
      <EmailVerificationBanner direction="row" />
      <main><Container maxW="container.lg">{children}</Container></main>
      <Footer />
      <CookieConsent enableDeclineButton cookieName="gatsby-gdpr-google-analytics" onAccept={() => initializeAndTrack(location)}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
};

export default Layout;