import { useAuth0 } from "@auth0/auth0-react";
import { Alert, AlertIcon, Box, Button, HStack, Stack } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useApi, usePromise } from "../util/useApi";
import ProblemErrorDetails from "./ProblemErrorDetails";

/** @type {React.FC<{direction: 'column'|'row'}>} */
const EmailVerificationBanner = ({ direction }) => {
  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const { isBusy: resendIsBusy, apiResult, call: resendCall } = useApi(useCallback(api => api.sendVerificationEmail(), []));
  const { isBusy: refreshIsBusy, result, call: refreshCall } = usePromise(useCallback(() => getAccessTokenSilently({ignoreCache: true}), [getAccessTokenSilently]));
  const isBusy = resendIsBusy || refreshIsBusy;

  if (isLoading || !user || user.email_verified) {
    return null;
  }

  return (
  <Alert status="warning">
    <Stack direction={direction} align={"center"}>
      <HStack><AlertIcon/><Box>Please verify your email address before creating a subscription.</Box></HStack>
      <Button onClick={() => refreshCall()} isLoading={refreshIsBusy} loadingText="Refreshing..." disabled={isBusy}>Refresh</Button>
      <Button onClick={() => resendCall()} isLoading={resendIsBusy} loadingText="Sending email" disabled={isBusy}>Resend verification email</Button>
      {!resendIsBusy && apiResult && apiResult.type !== 'SUCCESS' && <ProblemErrorDetails problemErrorResult={apiResult} />}
      {!refreshIsBusy && result && result.type !== 'SUCCESS' && <Box>Refresh error: {result.error.message}</Box>}
    </Stack>
  </Alert>);
};

export default EmailVerificationBanner;