import { Link as GatsbyLink } from "gatsby";
import { Box, Container, HStack, Image, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Box
      as="footer"
      bg={useColorModeValue('gray.100', 'gray.900')}
      marginTop={8}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <HStack spacing={4} alignItems="center"><Image w='30px' h='30px' src='/logo30.png' display='inline' /> <Text fontWeight='bold' display='inline'>image-watch.com</Text></HStack>
        <Link as={GatsbyLink} to='/app'>My Subscriptions</Link>
        <Link as={GatsbyLink} to='/app/billing'>Billing</Link>
        <Link as={GatsbyLink} to='/privacy'>Privacy</Link>
        <Link as={GatsbyLink} to='/terms'>Terms and Conditions</Link>
        <Link as={GatsbyLink} to='/contact'>Contact</Link>
        <Text>&copy; {new Date().getFullYear()} image-watch.com. All rights reserved.</Text>
      </Container>
    </Box>
  );
}

export default Footer;