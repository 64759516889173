import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, useMemo } from "react";
import { apiFactory } from "./api";

/**
 * Hook that allows calling API methods, using Bearer authentication if authenticated and automatically refreshing auth tokens if requested by the backend.
 * @returns {import("./api").Api?}
 */
export const useApiWhenAvailable = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const api = useMemo(() => apiFactory(isAuthenticated, getAccessTokenSilently), [isAuthenticated, getAccessTokenSilently]);
  return isLoading ? null : api;
}

/**
 * Hook that allows calling API methods, using Bearer authentication if authenticated and automatically refreshing auth tokens if requested by the backend.
 * @returns {{isLoading: boolean; api: import("./api").Api;}}
 */
const useApiInternal = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const api = useMemo(() => apiFactory(isAuthenticated, getAccessTokenSilently), [isAuthenticated, getAccessTokenSilently]);
  return { isLoading, api };
}

/** @template T @typedef {{type: 'SUCCESS', result: T}} SuccessPromiseResult */
/** @typedef {{type: 'ERROR', error: Error}} ErrorPromiseResult */
/** @template T @typedef {SuccessPromiseResult<T> | ErrorPromiseResult} PromiseResult */

/** @template T @typedef {{isBusy: boolean, result?: PromiseResult<T>}} PromiseState */

/**
 * Calls a Promise on demand.
 * @template T
 * @param {() => Promise<T>} func The function. This should be memoized via `useCallback`.
 */
export const usePromise = (func) => {
  /** @type {PromiseState<T>} */
  const initialState = {
    isBusy: false,
    result: null,
  };
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let cancelled = false;
    if (!state.isBusy) return;

    const invoke = async () => {
      try {
        const result = await func();
        if (cancelled) return;
        setState({ isBusy: false, result: { type: 'SUCCESS', result }});
      } catch (error) {
        if (cancelled) return;
        setState({ isBusy: false, result: { type: 'ERROR', error }});
      }
    };

    invoke();
    return () => { cancelled = true; };
  }, [state.isBusy, setState, func]);

  return { ...state, call: () => { setState({isBusy: true, result: null}); } };
}

/**
 * @template T
 * @typedef {{ isBusy: boolean; apiResult: import("./api").ApiResult<T> }} ApiState
 **/

/**
 * Calls an API on demand.
 * @template T
 * @param {(api: import("./api").Api, canceled?: () => boolean) => Promise<import("./api").ApiResult<T>>} fetch The API call function. This should be memoized via `useCallback`.
 */
export const useApi = (fetch) => {
  /** @type {ApiState<T>} */
  const initialState = {
    isBusy: false,
    apiResult: null,
  };
  const [state, setState] = useState(initialState);
  const { isLoading, api } = useApiInternal();
  useEffect(() => {
    let cancelled = false;
    if (isLoading) return;
    if (!state.isBusy) return;

    const invoke = async () => {
      const result = await fetch(api, () => cancelled);
      if (cancelled) return;
      setState({isBusy: false, apiResult: result});
    };

    invoke();
    return () => { cancelled = true; };
  }, [isLoading, state.isBusy, setState, api, fetch]);

  return { ...state, call: () => { setState({isBusy: true, apiResult: null}); } };
}

/**
 * @template T
 * @typedef {{ isLoading: boolean; apiResult: import("./api").ApiResult<T> }} ApiCallState
 **/

/**
 * Calls an API on component load.
 * @template T
 * @param {(api: import("./api").Api, canceled?: () => boolean) => Promise<import("./api").ApiResult<T>>} fetch The API call function. This should be memoized via `useCallback`.
 * @returns {ApiCallState<T>}
 */
export const useApiCall = (fetch) => {
  /** @type {ApiCallState<T>} */
  const initialState = {
    apiResult: null,
    isLoading: true,
  };
  const [state, setState] = useState(initialState);
  const { isLoading, api } = useApiInternal();

  useEffect(() => {
    let cancelled = false;
    const fetchAsync = async () => {
      const apiResult = await fetch(api, () => cancelled);
      if (cancelled) return;
      setState({ isLoading: false, apiResult });
    };

    if (isLoading) {
      return;
    }

    fetchAsync();
    return () => { cancelled = true; };
  }, [isLoading, fetch, api]);
 
  return state;
};
