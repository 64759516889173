import React from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Link } from "@chakra-ui/react";

/**
 * 
 * @param {{ problemErrorResult: import("../util/api").ErrorResult | import("../util/api").ProblemResult }} props 
 * @returns 
 */
const ProblemErrorDetails = ({ problemErrorResult }) => {
  if (problemErrorResult.type === 'ERROR') {
    return <Alert status="error"><AlertIcon/><AlertTitle>{problemErrorResult.error.message}</AlertTitle></Alert>;
  }
  return (<Alert status="error"><AlertIcon/>
    <AlertTitle>{problemErrorResult.problemDetails.status} {problemErrorResult.problemDetails.title}</AlertTitle>
    <AlertDescription>
      {problemErrorResult.problemDetails.detail && <Box>Details: {problemErrorResult.problemDetails.detail}</Box>}
      <Box>If you <Link href={`mailto:support@image-watch.com?subject=${problemErrorResult.problemDetails.instance}`}>contact support</Link>, please mention this problem id: {problemErrorResult.problemDetails.instance}</Box>
    </AlertDescription>
  </Alert>);
};

export default ProblemErrorDetails;