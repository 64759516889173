const backendUri = process.env.GATSBY_AUTH0_AUDIENCE;

/**@typedef {{status: number; type: string; title: string; detail?: string; instance: string;}} ProblemDetails */

/** @template T @typedef {{type: 'SUCCESS', result: T}} SuccessResult */
/** @typedef {{type: 'PROBLEM', problemDetails: ProblemDetails}} ProblemResult */
/** @typedef {{type: 'ERROR', error: Error}} ErrorResult */
/** @template T @typedef {SuccessResult<T> | ProblemResult | ErrorResult} ApiResult */

/**
 * Invokes the provided API. This method handles getting and refreshing auth tokens and parsing responses.
 * @template T
 * @param {(init: RequestInit) => Promise<Response>} api 
 * @param {boolean} isAuthenticated
 * @param {(options?: import('@auth0/auth0-spa-js').GetTokenSilentlyOptions) => Promise<string>} getAccessTokenSilently
 * @param {boolean} [noResult]
 * @returns {Promise<ApiResult<T>>}
 */
const callApi = async (api, isAuthenticated, getAccessTokenSilently, noResult) => {
  try {
    const init = /** @type {RequestInit} */ ({ });
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({ audience: backendUri, scope: "email" });
      init.headers = { "Authorization": `Bearer ${token}` };
    }

    const response = await api(init);
    if (response.headers.has('X-Refresh-Auth0')) {
      await getAccessTokenSilently({ audience: backendUri, scope: "email", ignoreCache: true });
    }

    if (response.headers.get('Content-Type') === 'application/problem+json') {
      return { type: 'PROBLEM', problemDetails: await response.json() };
    }

    if (!response.ok) {
      throw new Error(`Received status code ${response.status} ${response.statusText} from API.`);
    }

    if (noResult) {
      return { type: 'SUCCESS', result: null };
    } else {
      return { type: 'SUCCESS', result: await response.json() };
    }
  } catch (e) {
    return { type: 'ERROR', error: e };
  }
};

/** @typedef {{hub: string; image: string; tag: string; os: string; architecture: string; variant?: string; maximumWindowsVersion?: number;}} ImageResolver */
/** @typedef {{os: string; architecture: string; variant?: string;}} Target */
/** @typedef {{repositoryDigest: string; imageHash: string; imageResolver: ImageResolver;}} NewSubscription */
/** @typedef {{repositoryDigest: string; imageHash: string; imageResolver: ImageResolver; imageResolverHash: string;}} ExistingSubscription */

/**
 * The API
 * @param {boolean} isAuthenticated
 * @param {(options?: import('@auth0/auth0-spa-js').GetTokenSilentlyOptions) => Promise<string>} getAccessTokenSilently
 */
const apiFactory = (isAuthenticated, getAccessTokenSilently) => ({
  /**
   * Starts a Stripe billing portal session.
   * @param {{returnUrl: string}} request
   * @returns {Promise<ApiResult<{portalUrl: string;}>>}
   */
  billingPortal: async ({ returnUrl }) => await callApi(
    init => fetch(`${backendUri}/portal/billing`, { ...init, method: 'POST', body: JSON.stringify({ returnUrl }) }),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Either increments an existing customer subscription, or starts a Stripe payment portal session.
   * @param {{successUrl: string; cancelUrl: string; subscription: NewSubscription}} request
   * @returns {Promise<ApiResult<{portalId?: string;}>>}
   */
  paymentPortal: async ({ successUrl, cancelUrl, subscription }) => await callApi(
    init => fetch(`${backendUri}/portal/payment`, { ...init, method: 'POST', body: JSON.stringify({ successUrl, cancelUrl, subscription }) }),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Performs an immediate lookup of the requested image.
   * @param {{imageResolver: ImageResolver}} request
   * @returns {Promise<ApiResult<{imageHash: string; repositoryDigest: string;}>>}
   */
  lookup: async ({ imageResolver }) => await callApi(
    init => fetch(`${backendUri}/lookup?hub=${imageResolver.hub}&image=${imageResolver.image}&tag=${imageResolver.tag}&os=${imageResolver.os}&architecture=${imageResolver.architecture}&variant=${imageResolver.variant || ''}&build=${imageResolver.maximumWindowsVersion || ''}`, init),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Gets the current user's subscriptions.
   * @returns {Promise<ApiResult<{subscriptions: ExistingSubscription[]}>>}
   */
  getSubscriptions: async () => await callApi(
    init => fetch(`${backendUri}/subscriptions`, init),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Initializes a new subscription after the payment portal indicates success.
   * @param {string} stripePaymentSessionId
   * @returns {Promise<ApiResult<{}>>}
   */
  initSubscription: async (stripePaymentSessionId) => await callApi(
    init => fetch(`${backendUri}/subscriptions/init`, { ...init, method: 'POST', body: JSON.stringify({stripePaymentSessionId}) }),
    isAuthenticated,
    getAccessTokenSilently,
    true),

  /**
   * Deletes a subscription.
   * @param {string} imageResolverHash
   * @returns {Promise<ApiResult<{}>>}
   */
  deleteSubscription: async (imageResolverHash) => await callApi(
    init => fetch(`${backendUri}/subscriptions/delete`, { ...init, method: 'POST', body: JSON.stringify({imageResolverHash}) }),
    isAuthenticated,
    getAccessTokenSilently,
    true),

  /**
   * Gets the most common subscriptions.
   * @returns {Promise<ApiResult<{subscriptions: ImageResolver[]}>>}
   */
  getCommonSubscriptions: async () => await callApi(
    init => fetch(`${backendUri}/common`, init),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Gets the tags for a specific image.
   * @returns {Promise<ApiResult<{tags: string[]}>>}
   */
  getTags: async ({ hub, image }) => await callApi(
    init => fetch(`${backendUri}/tags?hub=${hub}&image=${image}`, init),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Gets the targets for a specific image.
   * @returns {Promise<ApiResult<{targets: Target[]}>>}
   */
  getTargets: async ({ hub, image, tag }) => await callApi(
    init => fetch(`${backendUri}/targets?hub=${hub}&image=${image}&tag=${tag}`, init),
    isAuthenticated,
    getAccessTokenSilently),

  /**
   * Sends a verification email.
   * @returns {Promise<ApiResult<{}>>}
   */
  sendVerificationEmail: async () => await callApi(
    init => fetch(`${backendUri}/verify`, { ...init, method: 'POST' }),
    isAuthenticated,
    getAccessTokenSilently,
    true),
});

/** @typedef {ReturnType<apiFactory>} Api */

export { apiFactory };