import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useColorModeValue, Box, Menu, MenuButton, Button, Avatar, MenuList, MenuItem, MenuDivider, Flex, HStack, Skeleton, Image, Text } from "@chakra-ui/react";

const NavLink = ({ to, children }) => (
  <Link
    as={GatsbyLink}
    px={2}
    py={1}
    rounded="md"
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    to={to}>
    {children}
  </Link>
);

const ProfileMenu = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  if (!isLoading && !isAuthenticated)
    return <button onClick={() => loginWithRedirect()}>Log in</button>;
  return (
    <Menu>
      <MenuButton
        data-testid="profile-menu"
        as={Button}
        rounded="full"
        variant="link"
        cursor="pointer">
        <Avatar
          size="sm"
          src={!isLoading && user.picture}
        />
      </MenuButton>
      {!isLoading &&
        <MenuList>
          <MenuItem as={GatsbyLink} to="/app/profile">Profile</MenuItem>
          <MenuItem as={GatsbyLink} to="/app/billing">Billing</MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log out</MenuItem>
        </MenuList>
      }
    </Menu>
  );
};

const NavBar = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} data-testid="navbar" marginBottom={8}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack spacing={8} alignItems="center">
          <HStack spacing={4} alignItems="center"><Image w='30px' h='30px' src='/logo30.png' display='inline' /> <Text fontWeight='bold' display='inline'>image-watch.com</Text></HStack>
          <HStack
            as="nav"
            spacing={4}
            display="flex">
            <Skeleton isLoaded={!isLoading}>{(isLoading || isAuthenticated) && <NavLink to="/app">My Subscriptions</NavLink>}</Skeleton>
          </HStack>
          <HStack
            as="nav"
            spacing={4}
            display="flex">
            <NavLink to="/docs">Documentation</NavLink>
          </HStack>
        </HStack>
        <Flex alignItems="center">
          <ProfileMenu />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;